import React from 'react';

import Form from '../components/Form';
import Edges from '../components/Edges';
import Seo from '../components/Seo';

import PageHeader from '../components/PageHeader';

const ContactPage = (props) => {
    const { uri } = props;

    const pageTitle = 'Contact';

    return (
        <>
            <Seo
                title={pageTitle}
                // description="Trades Training BC is a consortium of 15 public post-secondary educational institutions created to promote trades training in British Columbia."
            />

            <PageHeader title={pageTitle} breadcrumbs={[{ label: pageTitle, url: uri }]} />

            <Edges size="md">
                <div className="container mx-auto w-full">
                    <div className="flex flex-row flex-wrap items-start justify-between ">
                        <div className="mx-auto mb-[50px] mt-[37px] max-w-[411px] px-[10px] md:mx-[0px] lg:w-2/4 lg:px-0">
                            <p className="mb-[20px]">
                                Please fill in the form to contact Trades Training BC with a general question around the schedules. If you're looking for a trade that's not listed on our web-site, it could mean that our
                                member institutions do not presently offer it; please check the{' '}
                                <a href={'https://skilledtradesbc.ca/discover-apprenticeship-programs/search-programs'} target={'_blank'} className="text-blue">
                                    SkilledTradesBC programs
                                </a>{' '}
                                for a complete list. Also, private trainer schedules are not included.
                            </p>
                            <p>
                                We have a limited ability to answer Admission questions and the educational provider of choice should be contacted around these issues. Alternatively, please contact a
                                <a href={'https://skilledtradesbc.ca/our-trades-training-system/apprenticeship-advisors'} target={'_blank'} className="text-blue">
                                    {' '}
                                    SkilledTradesBC Apprentice Advisor
                                </a>{' '}
                                on general Admissions and Registration related questions.
                            </p>
                        </div>
                        <Form />
                    </div>
                </div>
            </Edges>
        </>
    );
};

export default ContactPage;
