import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";

import { postData } from "../utils/apiClient";

const Form = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [state, setState] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha();
        const values = { ...state, token };

        if (!values.name) {
            toast.error("Please enter a name");
            return;
        }

        if (!values.email) {
            toast.error("Please enter an email");
            return;
        }

        if (!values.message) {
            toast.error("Please enter a message");
            return;
        }

        setIsSubmitting(true);

        const status = await postData(`trades-training-bc/contact`, values);
        if (status && status === 200) {
            toast.success("Contact request submitted");

            setState({
                name: "",
                email: "",
                message: "",
            });
        }

        setIsSubmitting(false);
    };

    return (
        <div className="mx-auto mb-[40px] flex justify-center font-robotoCondensed lg:w-3/6">
            <form className="divide-gray-200 min-w-[250px] space-y-8 divide-y sm:w-[450px]">
                <div className="divide-gray-200 space-y-8 divide-y">
                    <div>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 ">
                            <div className="sm:col-span-6">
                                <label htmlFor="full-name" className="text-sm text-gray-700 block font-medium">
                                    * Full name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="full-name"
                                        name="name"
                                        type="text"
                                        value={state.name}
                                        autoComplete="given-name"
                                        className="  sm:text-sm block h-10 w-full rounded-[6px] border border-gray pl-2"
                                        maxLength={50}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="email" className="text-sm text-gray-700 block font-medium">
                                    * Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={state.email}
                                        className="  sm:text-sm block h-10 w-full rounded-[6px] border border-gray pl-2"
                                        maxLength={50}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="mb-[20px] sm:col-span-6">
                                <label htmlFor="message" className="text-sm text-gray-700 block font-medium">
                                    * Message
                                </label>
                                <div className="mt-1 w-full">
                                    <textarea
                                        id="about"
                                        name="message"
                                        rows={5}
                                        value={state.message}
                                        className="sm:text-sm block min-w-[250px] rounded-[6px] border border-gray p-2 sm:w-[450px]"
                                        defaultValue={""}
                                        maxLength={255}
                                        onChange={handleChange}
                                    />
                                    {state.message.length < 255 ? <p className="text-h3">{state.message.length}/255 characters</p> : <p className="text-h3 text-red">Character limit reached</p>}
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 py-3 pl-4 text-right sm:pl-6">
                            <button type="submit" disabled={isSubmitting} className="btn-primary" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default Form;
